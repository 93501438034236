<template>
  <div>
    <div class="DivBox">
      <p class="PTitle">体系文件分类数字化</p>
    </div>
    <div class="DivBox">
      <p class="PTitle LeftBor">服务介绍：</p>
      <p class="SmallP">
        企业的体系如果没有进行分类，就无法进行数字化管理。通常，体系文件分类文件层级金字塔开始，如下图：
      </p>
      <div class="ImgP">
        <img
          @click="openWindow"
          src="@/assets/FirstPage-ProductSon/3.png"
          style="width: 200px"
        />
        <p style="margin-top: 10px">体系文件层级金字塔</p>
      </div>
      <p class="SmallP">
        由于体系可以无限分层，所以经常会发生多层级的体系，如下表的情况。
      </p>
      <div class="ImgP">
        <p>文件分层示例</p>
        <img
          @click="openWindow"
          src="@/assets/FirstPage-ProductSon/1.png"
          class="SonImgW"
          style="margin: 0"
        />
      </div>
      <p class="SmallP">
        这样，体系就有很多分类。目前，很多企业对于自然形成的管理体系，没有刻意对这个体系进行分类，也就高不清有多少种类的文件了，这样不便于数字化管理。在上表中，可以为两大类：管理类和技术类。在管理类的文件中又可分为：“顾客导向过程”（Customer
        Oriented Process，COP）和“支持过程”（Supporting
        Process，SP）和“管理过程”（Management
        Process，MP）。此三类过程的划分是很多组织的习惯。三类过程的说明见下表：
      </p>
      <div class="ImgP">
        <p>表1-6 过程分类</p>
        <img
          @click="openWindow"
          src="@/assets/FirstPage-ProductSon/5.png"
          class="SonImgW"
        />
      </div>
      <p class="SmallP">这三类过程的关系如图：</p>
      <div class="ImgP">
        <img
          @click="openWindow"
          src="@/assets/FirstPage-ProductSon/4.png"
          class="SonImg"
        />
        <p>三类过程的关系</p>
      </div>
      <div class="PList">
        <p>过程数字化时，这三类过程的英文缩写可以直接当成数字编码的开头。</p>
        <p>
          顾客导向过程和支持过程统称为“业务过程”，并且一定存在几个决定经营业绩的关键过程——核心业务过程。“业务过程”与“管理过程”关系说明如下：
        </p>
        <p style="padding-left: 3%">
          1.
          业务过程与行业属性有关，反映出专业特点，大多是价值创造的过程，重复率比管理过程高；
        </p>
        <p style="padding-left: 3%">
          2.
          管理过程与行业属性无关。当业务过程反映出通用性和基础性的特点时就会衍生出管理过程，反过来又成为业务过程的基础和保证。组织业务规模越大，管理就越复杂，业务过程就越倚赖管理过程，这是组织成熟的标志。
        </p>
        <p>
          管理过程来自业务过程，反过来成为业务过程的基础，正如艺术来源于生活而高于生活那样。所以，管理过程才是组织的元基础，是获得制度自信的地方，是组织创新的重点领域，例如，决策机制、人才选拔机制和创新机制等。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openWindow() {
      window.open("https://www.yuntixi.cn", "_blank");
    },
  },
};
</script>

<style>
@import "../../../../assets/style/FirstProductSon.css";
</style>